html, body, #root {
	min-height: 100%;
}

html {
	height: 100%;
}

body {
	background: #222222 url("background.jpg") no-repeat fixed center top / cover;
	font-family: Verdana, sans-serif;
	font-size: 14px;
	position: relative;
	padding-bottom: 5em;
}

h1 {
	font-size: 26px;
	font-weight: 600;
}

h2 {
	font-size: 22px;
	font-weight: 600;
}

h3 {
	font-size: 18px;
	font-weight: 600;
}

header, footer {
	background-color: black;
}

header img {
	height: 50px;
	padding: 2px;
	margin: 10px 25px 10px 0;
}

a, .btn-link {
	color: #9f7705;
}

.btn:hover, .btn:focus {
	color: #000;
}

@media only screen and (min-width: 1920px) {
	body .container {
		max-width: 1600px;
	}
}

.btn-ptmd, .btn-register {
	padding: 6px 12px;
	text-transform: uppercase;
	letter-spacing: 2px;
	border: none;
	font-size: 11px;
	cursor: pointer;
}

.btn-ptmd {
	color: #fff;
	background-color: black;
}

.btn-register {
	color: black;
	background-color: #fff;
	border: 1px solid black;
	text-decoration: none;
}

.btn-register:hover, .btn-register:focus {
	background-color: #000;
	color: #fff;
}

form input.form-control {
	max-width: 300px;
}

footer {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 17px 0;
	font-size: 0.9em;
}

footer, footer a {
	color: #999999;
}

.email-login-container {
	background-color: #ebe8e4;
	border-radius: 50px 0 0 50px;
}